import React from 'react'
import { Document, PDFDownloadLink } from '@react-pdf/renderer'
import { IconCloudDownload } from 'mineral-ui-icons'
import Button from 'mineral-ui/Button'

import MultiDCPlaccard from './MultiDCPlaccard'
import SingleDCPlaccard from './SingleDCPlaccard'
import SinglePOPlaccard from './SinglePOPlaccard'
import PackingSlips from '../../../../components/reports/PackingSlip'

const Placcard = ({ type, centerPointId, purchaseOrders, dcNbrs, packingSlips }) => {
  return (
    <Document>
      <PackingSlips packingSlips={packingSlips} />
      {(() => {
        switch(type) {
          case 'singlePO':
            return <SinglePOPlaccard
                        centerPointId={centerPointId}
                        purchaseOrders={purchaseOrders}
                        dcNbr={dcNbrs[0]}
                        totalVolume={packingSlips[0].totalVolume}
                    />
          case 'singleDC':
            return <SingleDCPlaccard
                        dcNbr={dcNbrs[0]}
                        purchaseOrders={purchaseOrders}
                    />
          case 'multiDC':
            return <MultiDCPlaccard
                      purchaseOrders={purchaseOrders}
                    />
          default:
            return null
        }
      })()}
    </Document>
  )
}

export default props => {
  if(props.type === null) return null
  return (
    <PDFDownloadLink
      document={<Placcard {...props} />}
      fileName="placcards.pdf"
    >
      {() => (
        <Button
          as="div"
          iconStart={<IconCloudDownload circular />}
          variant="success"
          primary
          size="jumbo"
        >
          Download Placcards
        </Button>

      )}
    </PDFDownloadLink>
  )
}
