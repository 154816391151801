import React from 'react'
import styled from 'styled-components'
import gql from 'graphql-tag'
import { useQuery } from "@apollo/react-hooks"
import Text from 'mineral-ui/Text'
import Button from 'mineral-ui/Button'
import { FormField } from 'mineral-ui/Form'
import Flex, { FlexItem } from 'mineral-ui/Flex'
import Select from 'mineral-ui/Select'
import TextArea from 'mineral-ui/TextArea'
import Box from 'mineral-ui/Box'
import { IconLaunch, IconPrint } from 'mineral-ui-icons'
import { useAlert } from 'react-alert'
import Tooltip from 'mineral-ui/Tooltip'
import Loading from './../../../components/Loading'
import Dialog from 'mineral-ui/Dialog';

import Placcard from './Placcard'
import CaseLabels from './CaseLabels'

// Global Variables
const GET_DECONSOLIDATORS = gql`
  query getDeconsolidators {
    deconsolidators {
      name
      number
    }
  }
`

/**
  * Custom Dialog Class
  * Displays notification of Airflow error
  */
function Reprint({reprintFlag}) {
  /**
    * Toggles Dialog Visability
    */
    const [isOpen, setIsOpen] = React.useState(true)

  function closeDialog() {
    setIsOpen(false)
  }

    return (
      <div>
        <Dialog
          appSelector="#app"
          title="Labels Already Printed"
          actions={[
            { onClick: closeDialog, text: 'Ok' }
          ]}
          isOpen={isOpen}
          onClose={closeDialog}>
          <Text>
            One or more of the orders you have selected have already been printed!
          </Text>
        </Dialog>
      </div>
    )
}

/**
  * Main Method
  * @params [{Printer object}] printer
  */
export default ({ printer }) => {
  // Enviroment Setup
  const icon = <IconLaunch size="small" />
  const [airtableConnection, setAirtable] = React.useState(true)
  const [reprintFlag, setReprintFlag] = React.useState(false)
  const [submitting, setSubmitting] = React.useState(false)
  const [PONumbers, setPONumbers] = React.useState('')
  const [labelSize, setLabelSize] = React.useState({ text: 'Select label size...' })
  const [centerPointId, setCenterPointId] = React.useState({
    text: 'Select option...'
  })
  const [zpl, setZpl] = React.useState('')
  const [placcardData, setPlaccardData] = React.useState(null)
  const alert = useAlert()
  const res = useQuery(GET_DECONSOLIDATORS)
  var options = []

  // Check Airtable Status
  fetch(`/api/util/airtable-check`, {
    method: 'POST',
    cors: 'no-cors',
    body: null
  }).then( res => {
    if(res.status == 200){
      console.log("Airtable Successful")
      setAirtable(true)
    } else {
      console.log("Airtable Failed")
      setAirtable(false)
    }
  })

  // Load Deconsolidators
  if(res.loading){
    // Still Loading
    options = [{ text: 'Loading...'}]
  } else if (res.hasOwnProperty('data') && res.data) {
    // Loaded and Data Found
    options = res.data.deconsolidators.map(point =>  {
      return  {
        text: `${point.name} - ${point.number}`,
        id: point.number
      }
    })
  } else {
    // Loaded and Data Failed
    options = [{ text: "Unable to load deconsolidators"}]
  }

  /**
    * Handles changes within PO Num text box
    * @params [{String}] e [Value currently in textbox]
    */
  function handleChange(e) {
    const vals = e.target.value
      .split(/\n/)
      .map(s => s.replace(/[^\w\s]/, ''))
      .map(s => s.trim())
    setPONumbers(vals.join('\n'))
    setZpl('')
    setPlaccardData(null)
    setReprintFlag(false)
  }

  /**
    * Gets ZPL data for printing
    */
  function getZPL(e) {
    //  Enviroment Setup
    e.preventDefault()
    setSubmitting(true)

    const orders = PONumbers.split(/\n/)

    // Call to Label creator
    fetch(`/api/print-labels`, {
      method: 'POST',
      cors: 'no-cors',
      body: JSON.stringify({ orders, centerPointId: centerPointId.id })
    })
      .then(res => {
        // Convert and check if already printed
        if(res.status == 209){
          console.log("triggered")
          setReprintFlag(true)
        }
        return res.json()
      })
      .then((data) => {
        
        // Format Data
        setZpl(data.caseData)
        setPlaccardData(data.placcards)
        setSubmitting(false)
      })
      .catch(error => {
        // Handle Erros
        console.log(error)
        setSubmitting(false)
        alert.error('Double check the Purchase Order numbers you entered.')
      })
  }

  // Return View
  return (
    <Box padding="sm">
      <Text as="h3">Steps</Text>
      <Box padding="sm">
        <Text appearance="prose">
          1. Paste a single or list of Transaction IDs built on a single
          pallet separated by commas into the input box below.
        </Text>
        <Text appearance="prose">2. Click the “Generate Labels” button below.</Text>
        <Text appearance="prose">
          3. A pallet label will print first for a single pallet purchase order,
          and the UCC-128 Walmart labels will print in the SKU order listed in
          Pickline Order Airtable.
        </Text>
      </Box>
      <Button
        as="a"
        href="https://airtable.com/tblvi2IjbhjmCBY6M/viwZ4IWqXj0kbG5Af?blocks=hide"
        target="_blank"
        iconEnd={icon}
      >
        Update Pickline Order
      </Button>
      <Flex css={{ flexWrap: "wrap" }}>
        <Form as='form' shrink="1">

            <FormField required label="Select Deconsolidator" >
              <Select
                data={options}
                onChange={opt => setCenterPointId(opt)}
                selectedItem={centerPointId}
              />
            </FormField>
          <FormField
            required
            input={TextArea}
            value={PONumbers}
            onChange={handleChange}
            label="Transaction IDs"
          />
          <FormField required label="Carton Label Size" >
            <Select
              data={[{ id: 4, text: "4 x 4" },{ id: 6, text: "4 x 6" }]}
              onChange={opt => setLabelSize(opt)}
              selectedItem={labelSize}
            />
          </FormField>
          <Tooltip
            disabled={!!printer}
            cursor="not-allowed"
            content="Please connect to a printer before printing"
          >
            <Button
              iconEnd={submitting ? <Loading /> : null}
              disabled={!printer}
              onClick={getZPL}
              primary
            >
              GENERATE LABELS
            </Button>
          </Tooltip>
          </Form>
            {zpl && placcardData && !submitting && airtableConnection && (
              <FlexItem marginTop="20px" css={{ flexGrow: 1 }}>
                <Flex alignItems="center" css={{ justifyContent: "space-around" }}>
                  <CaseLabels disabled={!airtableConnection} printer={printer} caseData={zpl} labelSize={labelSize.id} orderData={placcardData.packingSlips} />
                  <Placcard
                    type={placcardData.placcardType}
                    centerPointId={placcardData.centerPointId}
                    purchaseOrders={placcardData.purchaseOrders}
                    dcNbrs={placcardData.dcNbrs}
                    packingSlips={placcardData.packingSlips}
                  />
                  </Flex>
              </FlexItem>
            )}
            {zpl && placcardData && !submitting && !airtableConnection && (
              <FlexItem marginTop="20px" css={{ flexGrow: 1 }}>
                <Flex alignItems="center" css={{ justifyContent: "space-around" }}>
                    <Tooltip content="Airtable is not connected. Unable to pull pickline data.">
                      <Button as="div" variant="danger">
                        Printing Blocked: Airtable Connection Failed
                      </Button>
                    </Tooltip>
                  </Flex>
              </FlexItem>
            )}
            {reprintFlag && airtableConnection && (
              <Reprint reprintFlag={reprintFlag}/>
            )}
      </Flex>
    </Box>
  )
}

const Form = styled(FlexItem)`
  width: 50%;
  max-width: 450px;
  flex-shrink: 1;

  & > div > label {
    width: 100%;
    max-width: 350px;
  }

  & > div,
  button {
    margin-top: 10px;
  }
`
