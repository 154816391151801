import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#b0b0b0'
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        borderBottomColor: '#b0b0b0',
        backgroundColor: '#b0b0b0',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 17,
        textAlign: 'center',
        fontStyle: 'bold',
        flexGrow: 1,
        fontSize: 10
    },
    sku: {
        width: '29%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    description: {
        width: '34%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    qty: {
        width: '8%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    unit: {
        width: '9%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    cubicFt: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
    weight: {
        width: '10%',
        textAlign: 'center'
    }
  });

  const PackingSlipTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.sku}>SKU</Text>
        <Text style={styles.description}>Description</Text>
        <Text style={styles.qty}>Quantity</Text>
        <Text style={styles.unit}>Unit</Text>
        <Text style={styles.cubicFt}>Cu Ft</Text>
        <Text style={styles.weight}>Lbs</Text>
    </View>
  );
  
  export default PackingSlipTableHeader