import React from 'react'
import { UserAgentApplication } from 'msal'

const MsalContext = React.createContext()

function MsalProvider(props) {

  const msalConfig = {
    auth: {
      clientId: '8540510e-ad11-4169-8c02-d38583f1b8ff',
      authority: 'https://login.microsoftonline.com/terrarose.com',
      postLogoutRedirectUri: 'https://www.tugg.us'
    },
    cache: {
      storeAuthStateInCookie: true
    }
  }

  const msal = new UserAgentApplication(msalConfig)

  const [user, setUser] = React.useState(null)

  msal.handleRedirectCallback((error, response) => {
    if(error) {
      console.log(error)
    } else {
      console.log('redirect callback', response)
    }
  })

  function login() {
    var loginRequest = {
        scopes: ["user.read", "profile"]
    }

   msal.acquireTokenSilent(loginRequest)
       .then(response => {
            var headers = new Headers();
            var bearer = "Bearer " + response.accessToken;
            headers.append("Authorization", bearer);
            var options = {
            	 method: "GET",
            	 headers: headers
            };
            var graphEndpoint = "https://graph.microsoft.com/v1.0/me/photo/$value";

            fetch(graphEndpoint, options)
              .then(res => {
                if(res.status === 404) {
                  throw new Error('No avatar found for user')
                }
                return res.blob()
              })
            	.then(blob => {
            		 const url = URL.createObjectURL(blob)
                 const user = msal.getAccount()
                 user.picture = url
                 setUser(user)
            	})
              .catch(err => {
                const user = msal.getAccount()
                user.picture = `https://ui-avatars.com/api/?size=128&name=${user.name}&background=0D8ABC&color=fff&bold=true`
                setUser(user)
              })
       })
       .catch(err => {
         msal.loginRedirect(loginRequest)
       })
  }

  function logout() {
    msal.logout()
  }


  return (
    <MsalContext.Provider
      value={{ login, logout, user }}
      {...props}
    />
  )
}

function useMsalAuth() {
  const context = React.useContext(MsalContext)
  if (context === undefined) {
    throw new Error(`useAuth must be used within a MsalProvider`)
  }
  return context
}

export { MsalProvider, useMsalAuth }
