import React from 'react'
import styled from 'styled-components'
import Text from 'mineral-ui/Text'
import Button from 'mineral-ui/Button'
import { FormField } from 'mineral-ui/Form'
import TextInput from 'mineral-ui/TextInput'
import Box from 'mineral-ui/Box'
import { useAlert } from 'react-alert'
import Loading from './../../components/Loading'
import Tooltip from 'mineral-ui/Tooltip'

export default ({ printer }) => {
  const [airtableConnection, setAirtable] = React.useState(true)
  const [submitting, setSubmitting] = React.useState(false)
  const [PONumber, setPONumber] = React.useState('')
  const [caseNumber, setCaseNumber] = React.useState('')
  const alert = useAlert()

  fetch(`/api/util/airtable-check`, {
    method: 'POST',
    cors: 'no-cors',
    body: null
  }).then( res => {
    if(res.status == 200){
      console.log("Airtable Successful")
      setAirtable(true)
    } else {
      console.log("Airtable Failed")
      setAirtable(false)
    }
  })

  function getZPL(e) {
    e.preventDefault()
    setSubmitting(true)

    fetch(`/api/print-single-label`, {
      method: 'POST',
      cors: 'no-cors',
      body: JSON.stringify({ PONumber, caseNumber })
    })
      .then(res => res.json())
      .then(data => {
        console.log(data)
        setSubmitting(false)
        printer.send(
          data.zpl,
          () => alert.show('print complete'),
          (e) => alert.error(e.message)
        )
      })
      .catch(error => {
        console.log(error)
        setSubmitting(false)
        alert.error(error.message)
      })
  }

  return (
    <Box padding="sm">
      <Text as="h3">Steps</Text>
      <Box padding="sm">
        <Text  appearance="prose">1. Paste the Transaction ID of the label you want to print.</Text>
        <Text  appearance="prose">2. Enter the case number of the PO that you want to print.</Text>
        <Text appearance="prose">3. Click the “Print Label” button below.</Text>
        <Text appearance="prose">4. The designated label on the purchase order will print.</Text>
      </Box>
      <Form>
        <FormField
          required
          value={PONumber}
          input={TextInput}
          type="number"
          label="Transaction ID"
          onChange={e => setPONumber(e.target.value)}
        />
        <FormField
          required
          value={caseNumber}
          input={TextInput}
          type="number"
          label="Case Number"
          onChange={e => setCaseNumber(e.target.value)}
        />
        {airtableConnection && (
          <Button
          iconEnd={submitting ? <Loading /> : null}
          onClick={getZPL}
          primary
          >
            PRINT LABEL
          </Button>)}
        {!airtableConnection && (
          <Tooltip content="Airtable is not connected. Unable to pull pickline data." css={{paddingTop: '10px'}}>
            <Button as="div" variant="danger">
              Printing Blocked: Airtable Connection Failed
            </Button>
          </Tooltip>
        )}
      </Form>
    </Box>
  )
}

const Form = styled.form`
  max-width: 450px;

  & > div > label {
    width: 100%;
    max-width: 350px;
  }

  & > div,
  button {
    margin-top: 10px;
  }
`
