import React, { Fragment } from 'react';
import {Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import JsBarcode from 'jsbarcode'

const styles = StyleSheet.create({
    infoContainer: {
        marginTop: 5,
        flexDirection: 'row',
        justifyContent: 'space-between',
        fontSize: 10
    },
    infoRowContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-start'
    },
    barcode:{
        width: 100
    }
  });

  const PackingSlipNo = ({packingSlip}) => {
    let canvas = document.createElement('canvas')
    JsBarcode (canvas, packingSlip.orderId, {
        width:1,
        height:30,
        displayValue: false
      })
    const barcode = canvas.toDataURL();
    return (
    <View style={styles.infoContainer}>
        <View>
            <View style={styles.infoRowContainer}>
                <Text>Order #:   </Text>
                <Text>{packingSlip.orderId}</Text>
            </View>
            <View style={styles.infoRowContainer}>
                <Text>Order Date:   </Text>
                <Text>{packingSlip.orderDate.split('T')[0]}</Text>
            </View>
            <View style={styles.infoRowContainer}>
                <Text>Reference #:   </Text>
                <Text>{packingSlip.refNum}</Text>
            </View>
            <View style={styles.infoRowContainer}>
                <Text>Vendor #:   </Text>
                <Text>{packingSlip.vendorId}</Text>
            </View>
        </View>
        <View></View>
        <View>
            <View style={styles.infoRowContainer}>
                <Text>PO #:   </Text>
                <Text>{packingSlip.poNum}</Text>
            </View>
            <View style={styles.infoRowContainer}>
                <Text>PO Type #:   </Text>
                <Text>{packingSlip.poTypeNum}</Text>
            </View>
            <View style={styles.infoRowContainer}>
                <Text>Location #:   </Text>
                <Text>{packingSlip.locationNum}</Text>
            </View>
            <View style={styles.infoRowContainer}>
                <Text>Department #:   </Text>
                <Text>{packingSlip.departNum}</Text>
            </View>
        </View>
        <View>
            <Image style={styles.barcode} src={barcode}/>
        </View>
    </View >
  )
};
  
  export default PackingSlipNo

  