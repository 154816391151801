import React from 'react'
import styled from 'styled-components'
import { Link } from '@reach/router'
import { ReactComponent as Logo } from './../assets/main-logo.svg'
import logo from './../assets/threepl.png'
import { useMsalAuth } from './../context/msal-context'
import StartEnd from 'mineral-ui/StartEnd'
import Avatar from 'mineral-ui/Avatar'
import Box from 'mineral-ui/Box'
import Flex, { FlexItem }  from 'mineral-ui/Flex'


export default () => {
  // const { user } = useMsalAuth()

  return (
    <HeaderContainer>
    <Header>
      <StartEnd
        justifyContent="center"
        padding="sm"
      >
        <Flex
          css={{
            display: 'flex',
            alignItems: 'center',
            height: '100%'
          }}
        >
          <FlexItem as={MainLogo} />
          <FlexItem
            css={{
              color: 'white',
              fontWeight: 'bold',
              fontSize: '28px',
              fontFamily: 'Industry',
              '@media (max-width: 490px)': {
                display: 'none'
              }
            }}
          >
            Tugg Tools
          </FlexItem>
        </Flex>

            <Flex css={{height: '100%'}} padding="sm">
              {/* {user && (
                <Avatar
                  css={{ marginRight: '15px'}}
                  as="a"
                  // onClick={logout}
                  target="_blank"
                  href="https://portal.office.com"
                >
                  <img src={user.picture} alt="avatar" />
                </Avatar>
              )} */}
              <Box
                as="a"
                target="_blank"
                href="https://secure-wms.com/smartui/?tplguid={dc6d74bf-189b-4ad6-9977-627988300ff0}"
              >
                <img src={logo} alt="3pl logo"/>
              </Box>
            </Flex>

      </StartEnd>
      <Nav>
        <NavItem to="/">Route Orders</NavItem>
        <NavItem to="/pallet-builder">Build Pallets</NavItem>
        <NavItem to="/print">Print Labels</NavItem>
      </Nav>
    </Header>
    </HeaderContainer>
  )
}

const HeaderContainer = styled.header`
  height: 260px;
  background-color: #1d1f24;
  padding: 0 30px;
`
const Header = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
`
const MainLogo = styled(Logo)`
  width: 100px;
  height: 24px;
  fill: ${props => props.theme.accentColor1};
`

const Nav = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  z-index: 1000;
  margin-top: 10px;
  position: relative;
  padding: 10px 0;

  &::before {
    background-image: linear-gradient(-90deg,rgba(255,255,255,0) 0,#fff 5%,#fff 90%,rgba(255,255,255,0) 100%);
    content: "";
    display: block;
    height: 2px;
    opacity: .08;
    position: absolute;
    top: 0;
    width: 100%;
  }
`
const RouterLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        'aria-selected': isCurrent,
        style: isCurrent
          ? {

              color: '#FFFFFF'
            }
          : {}
      }
    }}
  />
)

const NavItem = styled(RouterLink)`
  padding: 16px 24px;
  font-size: 14px;
  line-height: 0;
  font-weight: ${props => props.theme.demi};
  color: ${props => props.theme.accentColor2};
  border-radius: 7px;

  transition-duration: 0.5s;
  z-index: 2;

  @media (max-width: ${props => props.theme.phone}px) {
    padding: 16px;
  }

  &:hover {
    color: ${props => props.theme.accentColor1};
    background-color: ${props => props.theme.primaryColor};
    text-decoration: none;
  }
`
