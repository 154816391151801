import React from 'react';
import { Page, Document, Image, StyleSheet } from '@react-pdf/renderer';
import PackingSlipTitle from './PackingSlipTitle'
import ShippingBilling from './ShippingBilling'
import PackingSlipInfo from './PackingSlipInfo'
import PackingSlipItemsTable from './PackingSlipItemsTable'


const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 10,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        paddingBottom: 30,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    logo: {
        width: 60,
        height: 45,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
  });
  
  const PackingSlip = ({packingSlips}) => {
            
    const packingslips = packingSlips.map(packingSlip => 
        <Page size="letter" style={styles.page}>
            <PackingSlipTitle packingSlip={packingSlip}/>
            <PackingSlipInfo packingSlip={packingSlip}/>
            <ShippingBilling packingSlip={packingSlip}/>
            <PackingSlipItemsTable packingSlip={packingSlip} />
        </Page>
    )
    return packingslips
  };
  
  export default PackingSlip