import React from 'react'
import styled from 'styled-components'
import { red_50, green_50, slate_50, boxShadow_1, gray_90, gray_30 } from 'mineral-ui-tokens'
import { IconClose, IconErrorOutline, IconSuccess, IconInfo } from 'mineral-ui-icons'
import Box from 'mineral-ui/Box'
import Text from 'mineral-ui/Text'

export default ({ style, options, message, close }) => {
  let color, AlertIcon, title

  if(options.type === 'error') {
    color = red_50
    title = "Error"
    AlertIcon = IconErrorOutline
  } else if(options.type === 'success') {
    color = green_50
    title = "Success"
    AlertIcon = IconSuccess
  } else {
    color = slate_50
    title = "Info"
    AlertIcon = IconInfo
  }

  return (
    <Container style={{...style, margin: '10px auto'}}>
      <Header padding="sm" css={{paddingBottom: '.2em'}}>
        <Box css={{ display: 'flex'}}>
        <AlertIconContainer>
          <AlertIcon size="1.5em" color={color} />
        </AlertIconContainer>
        <Text css={{ marginBottom: 0, color: color}} appearance="h4">{title}</Text>
        </Box>
        <CloseContainer onClick={close}>
          <Close color={gray_90} size="large"  />
        </CloseContainer>
      </Header>
      <Box padding="md" css={{paddingTop: '.2em'}}>{ message }</Box>
    </Container>
  )
}


const Container = styled.div`
  position: relative;
  background: white;
  width: 500px;
  max-width: 100%;
  min-height: 60px;
  border-radius: 10px;
  box-shadow: ${boxShadow_1};
`
const Header = styled(Box)`
  display: flex;
  align-items: center;
  min-height: 32px;
  justify-content: space-between;
`
const CloseContainer = styled(Box)`
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 20px;
  transition-duration: .2s;

  &:hover {
    background: ${gray_30 };
  }
`
const Close = styled(IconClose)`
  width: 24px;
`
const AlertIconContainer = styled(Box)`
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: .5em;
`
