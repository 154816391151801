import React from 'react'
import styled from 'styled-components'
import Text from 'mineral-ui/Text'
import Button from 'mineral-ui/Button'
import { FormField } from 'mineral-ui/Form'
import Box from 'mineral-ui/Box'
import { useAlert } from 'react-alert'
import Loading from '../../components/Loading'
import Tooltip from 'mineral-ui/Tooltip'
import TextArea from 'mineral-ui/TextArea'
import Dialog from 'mineral-ui/Dialog';

export default () => {
  const [submitting, setSubmitting] = React.useState(false)
  const [PONumbers, setPONumbers] = React.useState('')
  const [voidResults, setVoidResults] = React.useState(false)
  const alert = useAlert()

  function handleChange(e) {
    const vals = e.target.value
      .split(/\n/)
      .map(s => s.replace(/[^\w\s]/, ''))
      .map(s => s.trim())
    setPONumbers(vals.join('\n'))
  }

  function voidLabels(e) {
    e.preventDefault()
    setSubmitting(true)

    const orders = PONumbers.split(/\n/)
    console.log(orders)
    fetch(`/api/void-labels`, {
      method: 'PUT',
      cors: 'no-cors',
      body: JSON.stringify({ orders })
    })
      .then(res => res.json())
      .then(data => {
        console.log(data)
        setSubmitting(false)
        setVoidResults(data)
      })
      .catch(error => {
        console.log(error)
        setSubmitting(false)
        alert.error(error.message)
      })
  }

  function VoidResults({voidResults}) {
    const [isOpen, setIsOpen] = React.useState(true)
  
    function closeDialog() {
      setIsOpen(false)
    }

    const results = []
    for (const [key, value] of Object.entries(voidResults)) {
      if (value) {
        results.push(<li key={key}>{key}: SUCCESS</li>)
      } else {
        results.push(<li key={key}>{key}: FAILED</li>)
      }
    }
  
      return (
        <div>
          <Dialog
            appSelector="#app"
            title="Void Results"
            actions={[
              { onClick: closeDialog, text: 'Ok' }
            ]}
            isOpen={isOpen}
            onClose={closeDialog}>
            <ul>
              {results}
            </ul>
          </Dialog>
        </div>
      )
  }

  return (
    <Box padding="sm">
      <Text as="h3">Steps</Text>
      <Box padding="sm">
        <Text as="h1" appearance="h4" color="#fc4903">Warning: this tool is only for small parcel labels generated through order labels page.</Text>
        <Text appearance="prose">1. Paste the Transaction IDs of the label you want to void.</Text>
        <Text appearance="prose">2. Click the "Void Labels" button below.</Text>
      </Box>
      <Form>
        <FormField
          required
          value={PONumbers}
          input={TextArea}
          type="number"
          label="Transaction IDs"
          onChange={handleChange}
        />
        <Button
          iconEnd={submitting ? <Loading /> : null}
          onClick={voidLabels}
          primary
          >
          VOID LABELS
        </Button>
      </Form>
      {voidResults && (
          <VoidResults voidResults={voidResults}/>
      )}
    </Box>
  )
}

const Form = styled.form`
  max-width: 450px;

  & > div > label {
    width: 100%;
    max-width: 350px;
  }

  & > div,
  button {
    margin-top: 10px;
  }
`
