import React, {Fragment} from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';
import { IconStoreMallDirectory } from 'mineral-ui-icons';

const borderColor = '#b0b0b0'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#b0b0b0',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 18,
        fontStyle: 'bold',
        fontSize: 10
    },
    sku: {
        width: '29%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    description: {
        width: '34%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '8%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    unit: {
        width: '9%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    cubicFt: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    weight: {
        width: '10%',
        textAlign: 'right',
        paddingRight: 8,
    },
  });


const PackingSlipTableRow = ({items}) => {
    const rows = items.map( (item , index)=> {
        return (
                <View style={styles.row} key={index+1}>
                    <Text style={styles.sku}>{item.sku}   SKU#: {item.itemNbr}</Text>
                    <Text style={styles.description}>{item.desc}</Text>
                    <Text style={styles.qty}>{item.qty}</Text>
                    <Text style={styles.unit}>{item.unit}</Text>
                    <Text style={styles.cubicFt}>{item.cubicFt}</Text>
                    <Text style={styles.weight}>{item.weight}</Text>
                </View>
            )
        }
    )
    return (<Fragment>{rows}</Fragment> )
};
  
export default PackingSlipTableRow