import React from 'react'
import Text from 'mineral-ui/Text'
import Box from 'mineral-ui/Box'

export default props => {
  return (
    <Box>
      <Text as="h3">Steps</Text>
      <Box padding="sm">
      <Text appearance="prose">
        1. Update the orders in 3PL Central with the assigned Load Number to
        palletize.
      </Text>
      <Text appearance="prose">2. Copy the assigned Load Number into the text box below.</Text>
      <Text appearance="prose">
        3. Using the Walmart provided load sheet, calculate the number of pallet
        spots you will utilize on the truck/load.
      </Text>
      <Text appearance="prose">4. Hit the “Calculate” button</Text>
      <Text appearance="prose">
        5. A spreadsheet with the calculated pallets will begin to download.
      </Text>
      </Box>
    </Box>
  )
}
