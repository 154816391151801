import React from 'react'
import { Router } from '@reach/router'
import styled from 'styled-components'
import { ThemeProvider } from 'styled-components'
import Helmet from 'react-helmet'
import { gray_20, boxShadow_1 } from 'mineral-ui-tokens';
import Text from 'mineral-ui/Text'
import Box from 'mineral-ui/Box'

import { useMsalAuth } from './context/msal-context'
import theme, { GlobalStyle } from './theme'
import Header from './components/Header'
import Route from './screens/Route'
import Palletize from './screens/Palletize'
import PrintLabels from './screens/Print'


export default props => {
  // const { user, login } = useMsalAuth()

  const user = true

  // React.useLayoutEffect(() => {
  //   if(!user) {
  //     login()
  //   }
  // }, [user, login])

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        <GlobalStyle />
        <Helmet>
          <title>Tugg Tools</title>
        </Helmet>
          <Header />
          <Main>
            <AppScreen>
              {user ? (
                <Router primary={false}>
                  <Route path="/" />
                  <Palletize path="/pallet-builder" />
                  <PrintLabels path="/print/*" />
                </Router>
              ):(
                <Box padding="lg">
                  <Text as="h2">Logging you in...</Text>
                </Box>
              )}
            </AppScreen>
          </Main>
      </React.Fragment>
    </ThemeProvider>
  )
}


const Main = styled.main`
  background-color: ${gray_20};
  padding: 0 30px;
  min-height: calc(100vh - 260px);
`
const AppScreen = styled.div`
  max-width: 1000px;
  margin: 0 auto;
  position: relative;
  top: -100px;
  background: white;
  border-radius: 10px;
  box-shadow: ${boxShadow_1};
  min-height: 400px;
`
