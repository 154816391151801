import React, { useState } from 'react'
import styled from 'styled-components'
import XLSX from 'xlsx'
import gql from 'graphql-tag'
import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import Button from 'mineral-ui/Button'
import Text from 'mineral-ui/Text'
import Box from 'mineral-ui/Box'
import Select from 'mineral-ui/Select'
import { FormField } from 'mineral-ui/Form'
import Instructions from './Instructions'
import Loading from './../../components/Loading'

const GET_OPEN_BATCHES = gql`
  query getBatches($id: ID!) {
    batches(customerId:$id) {
      id
      description
      customerName
    }
  }
`
const GET_SHIPPOINTS = gql`
  query getShippoints {
    shippoints {
      id
      customerName
      customerId
    }
  }
`

const RouteOrders = props => {
  const [submitting, setSubmitting] = React.useState(false)
  const [selectedShippoint, setSelectedShippoint] = useState({
    text: 'Select option...'
  })
  const [selectedBatch, setSelectedBatch] = useState({
    text: 'Select option...'
  })

  const shipRes = useQuery(GET_SHIPPOINTS)

  const shippointOptions = shipRes.loading ?
    [{ text: 'Loading...'}] :
    shipRes.error ?
    [{ text: 'Error...'}] :
    shipRes.data.shippoints.map(point =>  {

      return  {
        text: point.customerName,
        secondaryText: point.id,
        customerId: point.customerId
      }
    })

    const [
      loadBatches,
      { called, loading, data, error }
    ] = useLazyQuery(GET_OPEN_BATCHES)

    const batchOptions = !called ?
      [{ text: 'Select a Shippoint'}] :
      loading ?
      [{ text: 'Loading...'}] :
      error ?
      [{ text: 'Error...'}] :
      data.batches.map(batch =>  {
        return  {
          text: `${batch.id} - ${batch.description}`,
          secondaryText: batch.customerName,
          id: batch.id
        }
      }).sort((a,b) => b.id - a.id)


  function getRoutingSheet(e) {
    e.preventDefault()
    setSubmitting(true)

    fetch(`/api/route-orders`, {
      method: 'POST',
      cors: 'no-cors',
      body: JSON.stringify({
        shippoint: selectedShippoint.secondaryText,
        batchId: selectedBatch.id
      })
    })
      .then(res => res.json())
      .then(data => {
        const batches = chunk(data, 50)
        const wb = XLSX.utils.book_new()
        batches.forEach((batch, i) => {
          let ws = XLSX.utils.json_to_sheet(batch)
          XLSX.utils.book_append_sheet(wb, ws, `sheet_${i + 1}`)
        })
        XLSX.writeFile(wb, 'routing.xls')
        setSubmitting(false)
      })
      .catch(error => {
        console.log(error)
        setSubmitting(false)
        alert(error)
      })
  }

  return (
    <Box padding="lg" >
      <Text as="h1">Route Orders</Text>
        <Instructions />
        <Form>
          <FormField required label="Shippoint" >
            <Select
              data={shippointOptions}
              onChange={opt => {
                 setSelectedShippoint(opt)
                 loadBatches({ variables: { id: opt.customerId }})
              }}
              selectedItem={selectedShippoint}
            />
          </FormField>
          <FormField required label="Select Batch" >
            <Select
              data={batchOptions}
              onChange={opt => setSelectedBatch(opt)}
              selectedItem={selectedBatch}
            />
          </FormField>
          <Button
            css={{ textTransform: 'uppercase' }}
            iconEnd={submitting ? <Loading /> : null}
            onClick={getRoutingSheet}
            primary
          >
            Download Routing Sheet
          </Button>
          </Form>
    </Box>
  )
}

export default RouteOrders

const Form = styled.form`
  width: 100%;
  max-width: 450px;

  & > div > label {
    width: 100%;
    max-width: 350px;
  }

  & > div,
  button {
    margin-top: 10px;
  }

  & > button {
    max-width: 250px;
  }
`

function chunk(array, size) {
  const chunked_arr = []
  let index = 0
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index))
    index += size
  }
  return chunked_arr
}
