import React from 'react'
import styled from '@react-pdf/styled-components'


const SinglePOPlaccard = ({ centerPointId, dcNbr, purchaseOrders, totalVolume }) => {

  // Get number of placcards needed by dividing total volume to 70 then times 4
  let placcardsCount = Array.from({length: Math.ceil(totalVolume / 70) * 4}, (_, i) => i + 1)
 
  return placcardsCount.map((i) =>  (
    <Page key={i} orientation="landscape">
      <Text>---------------------------</Text>
      <Text>Pallet is not consolidated. </Text>
      <Text>Pallet contains items from the PO:</Text>
      <Text>---------------------------</Text>
      <LargeText>CP {centerPointId}</LargeText>
      {/* <LargeText>DC <LargeText style={{ textDecoration: 'underline'}}>{dcNbr}</LargeText></LargeText> */}
      <LargeText>
        {purchaseOrders[0].orderNbr} - {purchaseOrders[0].cases}{' '}
        Cases
      </LargeText>
    </Page>
  ))
}

export default SinglePOPlaccard

const Page = styled.Page`
  margin: 10px 5px 20px 5px;
`
const LargeText = styled.Text`
  font-size: 62px;
  font-family: 'Open Sans';
  text-align: center;
`
const Text = styled.Text`
  font-size: 46px;
  font-family: 'Open Sans';
  text-align: center;
`
