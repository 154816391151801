import React from 'react'
import { Page, Text } from './styles'

const SingleDCPlaccard = ({ dcNbr, purchaseOrders }) => {
  return [1,2,3,4].map((i) => (
    <Page key={i} orientation="landscape">
      <Text>Multi PO Same DC <Text style={{ textDecoration: 'underline'}}>{dcNbr}</Text>:</Text>
      <Text>PO's</Text>
      {purchaseOrders.map((po, i) => (
        <Text key={i}>
          {po.orderNbr} - {po.cases} cases
        </Text>
      ))}
    </Page>
  ))
}

export default SingleDCPlaccard
