import React from 'react';
import {Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import JsBarcode from 'jsbarcode'

const styles = StyleSheet.create({
   
    titleContainer:{
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginTop: 5
    },
    packingSlipTitle:{
        fontSize: 20,
        fontWeight: 'bold'
    },
    packingSlipText: {
        fontWeight: 'bold',
        fontSize: 15
    },
    barcode:{
        width: 100
    }
  });

  

    const PackingSlipTitle = ({packingSlip}) => {

        return (
            <View style={styles.titleContainer}>
                <Text style={styles.packingSlipTitle}>{packingSlip.customerName}</Text>
                <Text style={styles.packingSlipText}>Packing Slip</Text>
            </View>
        )
    };
  
  export default PackingSlipTitle