import React from 'react'
import styled from 'styled-components'
import Button from 'mineral-ui/Button'
import { FormField } from 'mineral-ui/Form'
import TextInput from 'mineral-ui/TextInput'
import Box from 'mineral-ui/Box'
import Text from 'mineral-ui/Text'
import XLSX from 'xlsx'
import { useAlert } from 'react-alert'

import Loading from './../../components/Loading'
import Instructions from './Instructions'

export default props => {
  const [submitting, setSubmitting] = React.useState(false)
  const [palletCount, setPalletCount] = React.useState('')
  const [loadNumber, setLoadNumber] = React.useState('')

  const alert = useAlert()

  function getPallets(e) {
    e.preventDefault()
    setSubmitting(true)


    fetch(`/api/build-pallets`, {
      method: 'POST',
      cors: 'no-cors',
      body: JSON.stringify({
        palletCount,
        loadNumber
      })
    })
      .then(res => res.json())
      .then(data => {
        if(data.error) {
          throw new Error(data.error.message)
        }
        const wb = XLSX.utils.book_new()
        let ws = XLSX.utils.json_to_sheet(data.summary)
        XLSX.utils.book_append_sheet(wb, ws, `sheet`)
        XLSX.writeFile(wb, 'pallet_summary.xls')
        setSubmitting(false)
        setLoadNumber('')
        setPalletCount('')
      })
      .catch(error => {
        console.log(error.message)
        setSubmitting(false)
        alert.error(error.message)
      })
  }

  return (
    <>
      <Box padding="lg">
        <Text as="h1">Build Pallets</Text>
        <Instructions />
        <Form>
          <FormField
            required
            label="Load Number"
            value={loadNumber}
            onChange={e => setLoadNumber(e.target.value)}
          >
            <TextInput type="number" required={true} />
          </FormField>
          <FormField
            required
            input={TextInput}
            label="# of Pallet Spots"
            value={palletCount}
            onChange={e => setPalletCount(e.target.value)}
          >
            <TextInput type="number" required={true} />
          </FormField>
          <Button
            iconEnd={submitting ? <Loading /> : null}
            type="submit"
            onClick={getPallets}
            primary
          >
            CALCULATE
          </Button>
        </Form>
      </Box>
    </>
  )
}

const Form = styled.form`
  max-width: 450px;

  & > div > label {
    width: 100%;
    max-width: 350px;
  }

  & > div,
  button {
    margin-top: 10px;
  }
`
