import React from 'react';
import {View, StyleSheet } from '@react-pdf/renderer';
import PackingSlipTableHeader from './PackingSlipTableHeader'
import PackingSlipTableRow from './PackingSlipTableRow'
import PackingSlipTableFooter from './PackingSlipTableFooter'

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 5,
        borderWidth: 1,
        borderColor: '#b0b0b0',
    },
});

  const PackingSlipItemsTable = ({packingSlip}) => (
    <View style={styles.tableContainer}>
        <PackingSlipTableHeader />
        <PackingSlipTableRow items={packingSlip.orderItems} />
        <PackingSlipTableFooter packingSlip={packingSlip} />
    </View>
  );
  
  export default PackingSlipItemsTable