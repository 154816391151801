import React from 'react'
import { Router, Link } from '@reach/router'
import { SecondaryNav, NavItem } from 'mineral-ui/Navigation'
import Text from 'mineral-ui/Text'
import Flex, { FlexItem } from 'mineral-ui/Flex'
import Box from 'mineral-ui/Box'
import Button from 'mineral-ui/Button'
import Tooltip from 'mineral-ui/Tooltip'
import Warning from 'mineral-ui-icons/IconWarning'
import PrintOrderLabels from './PrintOrderLabels'
import PrintSingleLabel from './PrintSingleLabel'
import VoidLabels from './VoidLabels'
import BrowserPrint from './BrowserPrint'

export default props => {
  const [zplPrinter, setZplPrinter] = React.useState(null)
  const [airtableConnection, setAirtable] = React.useState(true)

  React.useEffect(() => {
    fetch(`/api/util/airtable-check`, {
      method: 'POST',
      cors: 'no-cors',
      body: null
    }).then( res => {
      if(res.status == 200){
        console.log("Airtable Successful")
        setAirtable(true)
      } else {
        console.log("Airtable Failed")
        setAirtable(false)
      }
    })
  }, [])

  React.useEffect(() => {
    BrowserPrint.getDefaultDevice(
      'printer',
      function(device) {
        setZplPrinter(device)
      },
      function(error) {
        console.log(error)
      }
    )
  }, [setZplPrinter])

  return (
    <>
    <Box padding="lg">
      <Flex wrap alignItems="center" justifyContent="between">
        <FlexItem>
          <Text as="h1">Print Labels</Text>
        </FlexItem>
        <div css={{justifyContent: 'flex-end'}}>
          <FlexItem css={{overflow: 'hidden'}}>
            {zplPrinter ? (
              <Tooltip content="Use the Zebra Browser Print Utility to change the default printer.">
                <Button as="div" variant="success">
                  Connected to {zplPrinter.name}
                </Button>
              </Tooltip>
            ) : (
              <Tooltip content="Make sure Zebra Browser Print is running on your computer">
                <Button iconStart={<Warning />} as="div" variant="danger">
                  No printer connected
                </Button>
              </Tooltip>
            )}
          </FlexItem>
          <FlexItem css={{overflow: 'hidden',paddingTop: '5px'}}>
            {airtableConnection ? (
              <Tooltip content="Airtable is connected. Pickline data is avaliable.">
                <Button as="div" variant="success">
                  Connected to Airtable
                </Button>
              </Tooltip>
            ) : (
              <Tooltip content="Airtable connection has been lost. No Pickline data will be avaliable">
                <Button iconStart={<Warning />} as="div" variant="danger">
                  Airtable is not connected
                </Button>
              </Tooltip>
            )}
          </FlexItem>
        </div>
      </Flex>

      <SecondaryNav itemAs={RouterLink}>
        <NavItem to="/print">Order Labels</NavItem>
        <NavItem to="/print/single">Single Label</NavItem>
        <NavItem to="/print/void">Void Labels</NavItem>
      </SecondaryNav>

      <Router primary={false}>
        <PrintOrderLabels printer={zplPrinter} path="/" />
        <PrintSingleLabel printer={zplPrinter} path="single" />
        <VoidLabels path="void" />
      </Router>
      </Box>
    </>
  )
}

const RouterLink = props => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      return {
        'aria-selected': isCurrent,
        style: isCurrent
          ? {
              backgroundColor: '#8e99ab',
              color: '#ffffff',
              borderColor: '#8e99ab'
            }
          : {}
      }
    }}
  />
)
