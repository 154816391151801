import styled from '@react-pdf/styled-components'
import { Font } from '@react-pdf/renderer'

import fontFile from './../../../../assets/OpenSans-Bold.ttf'

Font.register({
  family: 'Open Sans',
  src: fontFile
})

export const Page = styled.Page`
  margin: 30px 30px 20px 30px;
`
export const Text = styled.Text`
  font-size: 66px;
  font-family: 'Open Sans';
`
