import React from 'react'
import Text from 'mineral-ui/Text'
import Box from 'mineral-ui/Box'
export default props => {
  return (
    <Box>
      <Text as="h3">Steps</Text>
      <Box padding="sm">
      <Text appearance="prose">1. Select the Shippoint to be used in routing the orders.</Text>
      <Text appearance="prose">2. Choose which Batch of orders from 3PL Central you want to route.</Text>
      <Text appearance="prose">3. Click the “Download Routing Sheet” button below.</Text>
      <Text appearance="prose">
        4. The spreadsheet to submit in the Retail Link Transportation
        application will begin downloading automatically when it has calculated
        recommended dimensions.{' '}
      </Text>
    </Box>
    </Box>
  )
}
