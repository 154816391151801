import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#b0b0b0'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#b0b0b0',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 18,
        fontSize: 10,
        fontStyle: 'bold',
    },
    sku: {
        width: '29%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    description: {
        width: '34%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },
    qty: {
        width: '8%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    unit: {
        width: '9%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    cubicFt: {
        width: '10%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    weight: {
        width: '10%',
        textAlign: 'right',
        paddingRight: 8,
    }
  });

const InvoiceTableFooter = ({packingSlip}) => {
    const totalQty = packingSlip.orderItems.map(item => item.qty)
        .reduce((accumulator, currentValue) => accumulator + currentValue , 0)

    const totalCubicFt = packingSlip.orderItems.map(item => Number(item.cubicFt))
        .reduce((accumulator, currentValue) => accumulator + currentValue , 0)
    
    const totalWeight = packingSlip.orderItems.map(item => Number(item.weight))
        .reduce((accumulator, currentValue) => accumulator + currentValue , 0)

    return(    
        <View style={styles.row}>
            <Text style={styles.sku}>Total</Text>
            <Text style={styles.description}></Text>
            <Text style={styles.qty}>{totalQty}</Text>
            <Text style={styles.unit}></Text>
            <Text style={styles.cubicFt}>{totalCubicFt.toFixed(2)}</Text>
            <Text style={styles.weight}>{totalWeight.toFixed(2)}</Text>
        </View>
    )
};
  
  export default InvoiceTableFooter