import React from 'react'
import styled from '@react-pdf/styled-components'

const MultiDCPlaccard = ({ purchaseOrders }) => {
  const mainPlaccards = [1,2,3,4].map((i) =>  (
    <Page key={i} orientation="landscape">
      <Text>MULTI PO's &</Text>
      <Text>DESTINATIONS</Text>
      <Text>{purchaseOrders.length} PO/DC's</Text>
      <Text style={{ textDecoration: 'underline'}}>PALLET MUST BE BROKEN</Text>
      <Text style={{ textDecoration: 'underline'}}>DOWN AT CC</Text>
    </Page>
  ))

  const poNbrs = [5,6].map((i) => (
    <Page key={i} style={{marginTop: '10px'}} orientation="landscape">
      <Text style={{textAlign: 'left'}}>PO's</Text>
      {purchaseOrders.map((po, i) => (
        <Text key={i}>
          {po.orderNbr} - {po.cases} cases
        </Text>
      ))}
    </Page>
  ))

  return mainPlaccards.concat(poNbrs)
}

export default MultiDCPlaccard

const Page = styled.Page`
  margin: 80px 5px 10px 5px;
`

const Text = styled.Text`
  font-size: 64px;
  font-family: 'Open Sans';
  text-align: center;
`
